<template>
<div style="background:#fff;padding:20px;" :style="{'min-height':(windowHeight-80)+'px'}">
    <div class="group-title flex">
        <span class="txt">我的订单</span>
        <el-input v-model="search" style="width: 240px" placeholder="订单号" clearable @change='getVipOrderFun(1)' @clear="getVipOrderFun(1)"/>
    </div>
     <el-table :data="dataList" stripe style="width: 100%">
        <el-table-column prop="orderCode" label="订单号" />
        <el-table-column prop="orderDesc" label="订单" />
        <el-table-column prop="orderTime" label="日期" >
            <template #default="scope">
                <span>{{parseTime(new Date(scope.row.orderTime),'{y}-{m}-{d} {h}:{i}:{s}')}}</span>
            </template>
        </el-table-column>
        <el-table-column prop="payerTotal" label="支付金额" />
        <el-table-column prop="state" label="状态" >
            <template #default="scope">
                <span>{{scope.row.state==20?'已完成':scope.row.state==90?'已取消':'待支付'}}</span>
            </template>
        </el-table-column>
    </el-table>
    <el-pagination
        @current-change="handleCurrentChangeFun"
        :current-page="pageIndex"
        :page-size='pageSize'
        :hide-on-single-page="total<=pageSize?true:false"
        layout="prev, pager, next"
        :total="total">
    </el-pagination>
</div>
</template>
<script>
import {getVipOrder,addUserLog} from '@/http/basicsApi'
import {ref } from 'vue'
import {parseTime} from '@/utils'
import { useRoute } from 'vue-router'
export default {
    setup() {
        const route=useRoute();
        let search=ref('');
        let dataList=ref();
        const windowHeight =ref(document.documentElement.clientHeight);// 可视区的高度
        const currentUser=JSON.parse( localStorage.getItem('currentUser'));//当前用户信息
        let total=ref(0);
        let pageIndex=ref(1);
        let pageSize=ref(15);
        const getVipOrderFun= async(type)=>{
            if(type==1 ||type==0){
                pageIndex.value=1;
            }
            let params={
                userId:currentUser.userId.toString(),
                orderCode:search.value,
                state:20,
                pageIndex:pageIndex.value,
                pageSize:pageSize.value
            }
            let res=await getVipOrder(params);
            console.log(res);
            if(res.code==20000){
                total.value=res.data.totalCount;
                dataList.value=res.data.dataList;
            }
            //记录用户操作日志
            addUserLog(route.name,route.fullPath,type==0?'加载':'查询',JSON.stringify(params));
        }
        //分页
        const handleCurrentChangeFun=(val)=>{
            pageIndex.value=val;
            getVipOrderFun(2);
        }
        //加载订单列表
        getVipOrderFun(0);
        return{
            windowHeight,
            search,
            dataList,
            getVipOrderFun,
            parseTime,
            pageIndex,
            pageSize,
            total,
            handleCurrentChangeFun
        }
    },
}
</script>
<style lang='scss' scoped>
.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
